import { gql } from "@apollo/client";

export const LIST_VEHICLES = gql`
  query ListVehicles($input: ListVehiclesInput!) {
    vehicles(input: $input) {
      id
      type
      manufacturer
      model
      serviceProviderId
      externalId
    }
  }
`;

export const LIST_VEHICLE_MANUFACTURES = gql`
  query ListVehicleManufacturers($input: ListVehicleManufacturersInput!) {
    vehicleManufacturers(input: $input) {
      id
      models {
        id
        name
        type
      }
      name
    }
  }
`;

export const LIST_VEHICLE_TRACTORS_MANUFACTURES = gql`
  query ListTractorManufacturer {
    findCustomerTractorManufacturer {
      id
      name
      models {
        id
        name
      }
    }
  }
`;
