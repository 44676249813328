import { lazy } from "react";

import { Page } from "../Pages";

export const ReportsPage: Page = {
  component: lazy(() => import("./ReportsPage")),
  path: "/reports",
  createPath: (customerId: string) => `/customers/${customerId}/reports`,
  name: "reports",
};
