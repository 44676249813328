export enum BreadcrumbKey {
  customers = "customers",
  "service-orders" = "service-orders",
  applications = "applications",
  application = "application",
  properties = "properties",
  registration = "registration",
  seasons = "seasons",
  products = "products",
  "aerial-company" = "aerial-company",
  users = "users",
  reports = "reports",
  "post-application" = "post-application",
  "create-application" = "create-application",
  "upload-log" = "upload-log",
  "pre-application" = "pre-application",
}
