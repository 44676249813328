import { posthog } from "posthog-js";

export const initializePosthog = () => {
  if (process.env.REACT_APP_APP_ENV !== "development") {
    posthog.init(process.env.REACT_APP_POSTHOG_CLIENT_KEY || "", {
      api_host: process.env.REACT_APP_POSTHOG_HOST_URL,
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          password: true,
        },
      },
    });
  } else {
    console.debug("Debug: Initialized Posthog");
  }
};

export const identifyUser = (user: {
  email: string;
  name: string;
  role: string[];
}) => {
  if (process.env.REACT_APP_APP_ENV !== "development") {
    posthog.identify(user.email, {
      name: user.name,
      email: user.email,
      role: user.role,
    });
  } else {
    console.debug("Debug: Identified user in Posthog:", { user });
  }
};

export const resetPostHog = () => {
  if (process.env.REACT_APP_APP_ENV !== "development") {
    return posthog.reset(true);
  } else {
    console.debug("Debug: Resetted Posthog");
  }
};
