import { defineMessages } from "react-intl";

export const ApplicationTypeMessages = defineMessages({
  Aerial: {
    defaultMessage: "Aérea",
    id: "OmKbjw",
    description: "aerial label",
  },
  Terrestrial: {
    defaultMessage: "Terrestre",
    id: "XEAqz3",
    description: "terrestrial label",
  },
});
