import { gql } from "@apollo/client";

export const LIST_OPERATORS = gql`
  query ListOperators($input: ListOperatorsInput!) {
    operators(input: $input) {
      id
      name
      serviceProviderId
      externalId
    }
  }
`;
