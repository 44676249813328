import { gql } from "@apollo/client";

export const FETCH_WEATHER = gql`
  query FindCustomerApplicationWeather($scheduleId: ID!) {
    findCustomerApplicationWeather(scheduleId: $scheduleId) {
      id
      cloudiness
      createdAt
      dateType
      deletedAt
      humidity
      provider
      temperature
      updatedAt
      windDirection
      windSpeed
      applicationSchedule {
        id
        startedIn
        finishedIn
      }
      location {
        coordinates
        type
      }
    }
  }
`;
