import { defineMessages } from "react-intl";

export const ServiceOrderStatusMessages = defineMessages({
  Complete: {
    defaultMessage: "Processado",
    id: "boHuZO",
    description: "complete status label",
  },
  InProgress: {
    defaultMessage: "Processando Log",
    id: "F2/6Oi",
    description: "in progress status label",
  },
  Processing: {
    defaultMessage: "Processando Aplicação",
    id: "MN8nTU",
    description: "processing label",
  },
  ReadyToProcess: {
    defaultMessage: "Preencher",
    id: "7Zuq1O",
    description: "fill info status label",
  },
  WaitingJob: {
    defaultMessage: "Pendente",
    id: "HuDrZE",
    description: "waiting job label",
  },
});
