import { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AuthContext } from "@contexts/Authentication";
import { CustomerContext } from "@contexts/Customer";

import { SidebarDrawer } from "@components/SidebarDrawer";

import { AerialCompanyPage } from "@pages/AerialCompany";
import { CustomersPage } from "@pages/Customers";
import { CustomerUsersPage } from "@pages/CustomerUsers";
import { Error403Page, Error404Page, Error500Page } from "@pages/ErrorPage";
import { Page } from "@pages/Pages";
import { ProductsPage } from "@pages/Products";
import { PropertiesPage } from "@pages/Properties";
import { ReportsPage } from "@pages/Reports";
import { SeasonPage } from "@pages/Season";

import { ServiceOrdersRoutes } from "./ServiceOrdersRoutes";

const pages: Page[] = [
  PropertiesPage,
  ProductsPage,
  AerialCompanyPage,
  SeasonPage,
  CustomerUsersPage,
  ReportsPage,
  Error403Page,
  Error404Page,
  Error500Page,
];

const pilotsPages: Page[] = [Error403Page, Error404Page, Error500Page];

export const CustomerRoutes = () => {
  const { isCurrentUserPilot } = useContext(AuthContext);
  const { customer } = useContext(CustomerContext);

  if (customer === null) {
    return (
      <Routes>
        <Route
          path={CustomersPage.path}
          element={<CustomersPage.component />}
        />
      </Routes>
    );
  }

  return (
    <SidebarDrawer>
      <Routes>
        {isCurrentUserPilot ? (
          <>
            {pilotsPages.map((page) => (
              <Route
                key={page.path}
                path={page.path}
                element={<page.component />}
              />
            ))}
          </>
        ) : (
          <>
            {pages.map((page) => (
              <Route
                key={page.path}
                path={page.path}
                element={<page.component />}
              />
            ))}
          </>
        )}
        <Route
          path="/applications/*"
          element={
            <Navigate
              replace
              to={location.pathname.replace("applications", "service-orders")}
            />
          }
        />
        <Route
          path="/properties/service-orders/*"
          element={
            <Navigate
              replace
              to={location.pathname.replace(
                "properties/service-orders",
                "service-orders"
              )}
            />
          }
        />
        <Route path={"/service-orders/*"} element={<ServiceOrdersRoutes />} />
      </Routes>
    </SidebarDrawer>
  );
};
