import { gql } from "@apollo/client";

export const LIST_FILES = gql`
  query ListFiles($fileInput: ListFilesInput!) {
    files(input: $fileInput) {
      files {
        id
        customerId
        name
        originalFilename
        type
        status
        userId
        createdAt
        updatedAt
        metadata {
          startDateTime
          endDateTime
        }
        layers {
          name
          url
          bbox
        }
      }
      paging {
        limit
        offset
        total
      }
    }
  }
`;

export const GET_FILE = gql`
  query GetFile($input: FindFileInput!) {
    file(input: $input) {
      id
      name
      metadata {
        startDateTime
        endDateTime
      }
      layers {
        name
        url
        bbox
      }
      createdAt
    }
  }
`;

export const FILE_POLLING = gql`
  query FilePolling($input: FindFileInput!) {
    file(input: $input) {
      id
      type
      name
      status
    }
  }
`;
