import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { css, styled } from "@mui/material/styles";

export const Button = ({ children, ...props }: LoadingButtonProps) => (
  <ButtonWrapper {...props} disableElevation>
    {children}
  </ButtonWrapper>
);

const ButtonWrapper = styled(LoadingButton)`
  font-size: ${(props) => props.theme.typography.body1};
  font-weight: 500;
  text-transform: none;
  ${(props) =>
    props.variant === "contained" &&
    css`
      background-color: ${props.theme.palette.primary["main"]};
      color: ${props.theme.palette.common["white"]};
      &:hover {
        background-color: ${props.theme.palette.primary["dark"]};
      }
    `};
`;
