import { IntlShape } from "react-intl";

import {
  ApplicationAnalysisMethod,
  ApplicationDeletedReason,
  ApplicationType,
  Crop,
  CustomerRole,
  MeasurementUnitV2,
  ProductTypeV2,
  RestrictionFieldType,
  ServiceOrderStatus,
  TimeZone,
} from "@graphql/types/graphql-global-types";

import {
  AnalysisMethodMessages,
  ApplicationDeletedReasonMessages,
  ApplicationTypeMessages,
  BreadcrumbMessages,
  CropMessages,
  CustomerRoleMessages,
  MeasurementUnitMessages,
  ProductTypeMessages,
  RestrictionTypeMessages,
  ServiceOrderStatusMessages,
  TimezoneMessages,
} from "@core/translateMessages";

import { BreadcrumbKey } from "@type/breadcrumbs";

export class Translate {
  static crop(intl: IntlShape, crop: Crop) {
    return intl.formatMessage(CropMessages[crop]);
  }

  static analysisMethod(
    intl: IntlShape,
    analysisMethod: ApplicationAnalysisMethod
  ) {
    return intl.formatMessage(AnalysisMethodMessages[analysisMethod]);
  }

  static applicationType(intl: IntlShape, applicationType: ApplicationType) {
    return intl.formatMessage(ApplicationTypeMessages[applicationType]);
  }

  static customerRole(intl: IntlShape, customerRole: CustomerRole) {
    return intl.formatMessage(CustomerRoleMessages[customerRole]);
  }

  static productType(intl: IntlShape, productType: ProductTypeV2) {
    return intl.formatMessage(ProductTypeMessages[productType]);
  }

  static serviceOrderStatus(
    intl: IntlShape,
    serviceOrderStatus: ServiceOrderStatus
  ) {
    return intl.formatMessage(ServiceOrderStatusMessages[serviceOrderStatus]);
  }

  static restrictionType(
    intl: IntlShape,
    restrictionType: RestrictionFieldType
  ) {
    return intl.formatMessage(RestrictionTypeMessages[restrictionType]);
  }

  static timezone(intl: IntlShape, timezone: TimeZone) {
    return intl.formatMessage(TimezoneMessages[timezone]);
  }

  static measurementUnit(intl: IntlShape, measurementUnit: MeasurementUnitV2) {
    return intl.formatMessage(MeasurementUnitMessages[measurementUnit]);
  }

  static applicationDeletedReason(
    intl: IntlShape,
    applicationDeletedReason: ApplicationDeletedReason
  ) {
    return intl.formatMessage(
      ApplicationDeletedReasonMessages[applicationDeletedReason]
    );
  }

  static breadcrumb(intl: IntlShape, breadcrumb: BreadcrumbKey) {
    return intl.formatMessage(BreadcrumbMessages[breadcrumb]);
  }
}
