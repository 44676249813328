import { lazy } from "react";

import { Page } from "../Pages";

export const CreateApplicationPage: Page = {
  component: lazy(() => import("./CreateApplicationPage")),
  path: "/:serviceOrderId/post-application/create-application/",
  createPath: (customerId: string, serviceOrderId: string) =>
    `/customers/${customerId}/service-orders/${serviceOrderId}/post-application/create-application`,
  name: "create-application",
};
