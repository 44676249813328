import { useContext, useState } from "react";
import { useIntl } from "react-intl";

import { useLazyQuery } from "@apollo/client";

import { LIST_USERS } from "@graphql/queries/UserQuery";
import {
  FindCustomerUserInput,
  PaginateInput,
  SortCustomerUserInput,
  SortType,
} from "@graphql/types/graphql-global-types";
import {
  ListUsers as ListUsersInput,
  ListUsersVariables,
} from "@graphql/types/ListUsers";

import { Toast } from "@core/Toast";

import { CustomerContext } from "@contexts/Customer";

import { graphQLErrorMessage } from "@utils/errorMessages";
import { notEmpty } from "@utils/notEmpty";

import { ListUsers } from "@type/user";

interface LoadCustomerUsersInput {
  findCustomerUserInput?: Partial<FindCustomerUserInput>;
  paginateCustomerUserInput?: PaginateInput;
  sortCustomerUserInput?: SortCustomerUserInput;
}

export const useCustomerUsers = () => {
  const intl = useIntl();
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [users, setUsers] = useState<ListUsers[]>([]);
  const [usersTotal, setUsersTotal] = useState<number>(0);

  const [findCustomerUser, { loading: isLoadingUsers }] = useLazyQuery<
    ListUsersInput,
    ListUsersVariables
  >(LIST_USERS, {
    onCompleted: ({ findCustomerUser }) => {
      const filteredUsers = findCustomerUser.customerUsers.filter(notEmpty);
      setUsers(filteredUsers);
      setUsersTotal(findCustomerUser.total);
    },
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao listar os usuários, tente novamente mais tarde",
            id: "EMimIO",
            description: "error listing user",
          })
        );
      }

      throw error;
    },
    fetchPolicy: "network-only",
  });

  const onLoadCustomerUsers = ({
    findCustomerUserInput,
    paginateCustomerUserInput,
    sortCustomerUserInput = {
      createdAt: SortType.desc,
    },
  }: LoadCustomerUsersInput) => {
    return findCustomerUser({
      variables: {
        findCustomerUserInput: {
          customerId,
          ...findCustomerUserInput,
        },
        paginateCustomerUserInput,
        sortCustomerUserInput,
      },
    });
  };

  return {
    users,
    usersTotal,
    isLoadingUsers,
    onLoadCustomerUsers,
  };
};
