import { gql } from "@apollo/client";

export const LIST_AGRICULTURAL_DEFENSIVE_PRODUCTS = gql`
  query ListAgriculturalDefensiveProducts(
    $input: ListAgriculturalDefensiveProductsInput!
  ) {
    agriculturalDefensiveProducts(input: $input) {
      id
      manufacturerName
      measurementUnit
      name
      type
    }
  }
`;
