import { gql } from "@apollo/client";

export const LIST_PRODUCTS = gql`
  query ListProducts(
    $findSeasonProductInput: FindSeasonProductInput!
    $sortSeasonProductInput: SortSeasonProductInput
    $paginateSeasonProductInput: PaginateInput
  ) {
    findCustomerSeasonProduct(
      findSeasonProductInput: $findSeasonProductInput
      sortSeasonProductInput: $sortSeasonProductInput
      paginateSeasonProductInput: $paginateSeasonProductInput
    ) {
      seasonProducts {
        id
        alias
        manufacturerName
        measurementUnit
        type
        amount {
          currency
          value
        }
        season {
          id
          crop
          startedIn
          finishedIn
        }
      }
      total
    }
  }
`;

export const GET_PRODUCT = gql`
  query GetProduct($seasonProductId: ID!) {
    findCustomerSeasonProductById(seasonProductId: $seasonProductId) {
      id
      alias
      measurementUnit
      type
      manufacturerName
      amount {
        currency
        value
      }
      season {
        id
        crop
        startedIn
        finishedIn
      }
    }
  }
`;
