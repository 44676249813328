import { useContext, useState } from "react";
import { useIntl } from "react-intl";

import { useLazyQuery } from "@apollo/client";

import {
  LIST_SERVICE_ORDER_SCHEDULES,
  LIST_SERVICE_ORDERS,
} from "@graphql/queries/ServiceOrderQuery";
import {
  FindCustomerServiceOrderInput,
  PaginateInput,
  SortCustomerServiceOrderInput,
} from "@graphql/types/graphql-global-types";
import {
  ListServiceOrders as ListServiceOrdersInput,
  ListServiceOrdersVariables,
} from "@graphql/types/ListServiceOrders";
import {
  ListServiceOrderSchedules,
  ListServiceOrderSchedulesVariables,
} from "@graphql/types/ListServiceOrderSchedules";

import { Toast } from "@core/Toast";

import { CustomerContext } from "@contexts/Customer";

import { graphQLErrorMessage } from "@utils/errorMessages";
import { notEmpty } from "@utils/notEmpty";

import { Paging } from "@type/paging";
import {
  ListServiceOrders,
  ListServiceOrderSchedule,
} from "@type/service_order";

export const useServiceOrders = () => {
  const intl = useIntl();
  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [serviceOrders, setServiceOrders] = useState<ListServiceOrders[]>([]);
  const [serviceOrderPaging, setServiceOrderPaging] = useState<Paging | null>(
    null
  );

  const [serviceOrderSchedules, setServiceOrderSchedules] = useState<
    ListServiceOrderSchedule[]
  >([]);

  const [loadServiceOrders, { loading: isLoadingServiceOrders }] = useLazyQuery<
    ListServiceOrdersInput,
    ListServiceOrdersVariables
  >(LIST_SERVICE_ORDERS, {
    onCompleted: ({ findCustomerServiceOrder }) => {
      const filteredServiceOrder =
        findCustomerServiceOrder.serviceOrders.filter(notEmpty);
      setServiceOrders((currentServiceOrders) => [
        ...currentServiceOrders,
        ...filteredServiceOrder,
      ]);
      setServiceOrderPaging({
        limit: findCustomerServiceOrder.paginate.limit,
        offset: findCustomerServiceOrder.paginate.offset,
        total: findCustomerServiceOrder.paginate.total,
      });
    },
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Não foi possível recuperar as ordem de serviço. Tente novamente mais tarde.",
            id: "JIbdNi",
            description: "error listing service orders",
          })
        );
      }
    },
    fetchPolicy: "network-only",
  });

  const onLoadServiceOrders = ({
    findServiceOrderInput,
    paginateServiceOrderInput,
    sortServiceOrderInput,
  }: {
    findServiceOrderInput: Omit<FindCustomerServiceOrderInput, "customerId">;
    paginateServiceOrderInput?: PaginateInput | null;
    sortServiceOrderInput?: SortCustomerServiceOrderInput | null;
  }) =>
    loadServiceOrders({
      variables: {
        findServiceOrderInput: {
          customerId,
          ...findServiceOrderInput,
        },
        paginateServiceOrderInput,
        sortServiceOrderInput,
      },
    });

  const [
    listServiceOrderSchedules,
    { loading: isLoadingServiceOrderSchedules },
  ] = useLazyQuery<
    ListServiceOrderSchedules,
    ListServiceOrderSchedulesVariables
  >(LIST_SERVICE_ORDER_SCHEDULES, {
    onCompleted: ({ findCustomerServiceOrder }) => {
      const serviceOrderSchedules =
        findCustomerServiceOrder.serviceOrders.filter(notEmpty);

      setServiceOrderSchedules(serviceOrderSchedules);
    },
  });

  const onListServiceOrderSchedule = (slug?: string) =>
    listServiceOrderSchedules({
      variables: {
        findServiceOrderInput: {
          customerId,
          slug,
        },
      },
    });

  const onClearServiceOrders = () => {
    setServiceOrders([]);
  };

  return {
    serviceOrders,
    serviceOrderPaging,
    isLoadingServiceOrders,
    onLoadServiceOrders,
    onClearServiceOrders,

    serviceOrderSchedules,
    isLoadingServiceOrderSchedules,
    onListServiceOrderSchedule,
  };
};
