import { defineMessages } from "react-intl";

export const CustomerRoleMessages = defineMessages({
  CustomerAdmin: {
    defaultMessage: "Admin do Cliente",
    id: "ZizUIC",
    description: "customer admin label",
  },
  CustomerPilot: {
    defaultMessage: "Usuário Piloto",
    id: "/EweUe",
    description: "pilot user label",
  },
  PartnerAdmin: {
    defaultMessage: "Parceiro",
    id: "t3wo12",
    description: "partner user label",
  },
});
