import { GraphQLError } from "graphql";

import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  FetchResult,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  ServerError,
} from "@apollo/client";
import { NetworkError } from "@apollo/client/errors";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

import { refreshSession, signOut } from "@service/cognito";

const LAST_AUTH_USER_LOCAL_STORAGE_PATH = `CognitoIdentityServiceProvider.${process.env.REACT_APP_USER_POOL_APP_CLIENT_ID}.LastAuthUser`;
const BASE_URL = process.env.REACT_APP_PUBLIC_URL || "";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URI,
});

const authLink = setContext(async (_, { headers = {} }) => {
  const token = localStorage.getItem(
    `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_USER_POOL_APP_CLIENT_ID
    }.${localStorage.getItem(LAST_AUTH_USER_LOCAL_STORAGE_PATH)}.accessToken`
  );

  return {
    headers: Object.assign(headers, {
      ...(token && { Authorization: `Bearer ${token}` }),
    }),
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (
          err.extensions.code === "UNAUTHENTICATED" ||
          err.extensions.code === "UNAUTHORIZED"
        ) {
          return retryConnection(operation, forward);
        }
      }
    }

    if (isServerError(networkError)) {
      if (isUnauthorizedError(networkError)) {
        return retryConnection(operation, forward);
      }
    }
  }
);

const retryConnection = (operation: Operation, forward: NextLink) => {
  return new Observable<FetchResult<Record<string, unknown>>>((observer) => {
    // used an anonymous function for using an async function
    (async () => {
      try {
        const token = await refreshSession();

        if (!token) {
          throw new GraphQLError("Empty AccessToken");
        }

        // Retry the failed request
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        forward(operation).subscribe(subscriber);
      } catch (err) {
        console.error({ err });
        signOut(
          localStorage.getItem(LAST_AUTH_USER_LOCAL_STORAGE_PATH) || undefined
        );
        window.location.href = BASE_URL;
        observer.error(err);
      }
    })();
  });
};

const isServerError = (
  error: NetworkError | undefined
): error is ServerError => {
  if (error && "statusCode" in error) {
    return true;
  }

  return false;
};

const isUnauthorizedError = (networkError: ServerError) => {
  return networkError.statusCode === 401;
};

export const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});
