import { lazy } from "react";

import { Page } from "@pages/Pages";

export const ServiceOrdersPage: Page = {
  component: lazy(() => import("./ServiceOrdersPage")),
  path: "/",
  createPath: (customerId: string) => `/customers/${customerId}/service-orders`,
  name: "service-orders",
};
