import { gql } from "@apollo/client";

export const LIST_REPORTS = gql`
  query ListReports($input: FetchAllReportsInput!) {
    fetchAllReports(input: $input) {
      id
      name
    }
  }
`;

export const GET_REPORT = gql`
  query GetReport($input: FetchReportInput!) {
    fetchReport(input: $input) {
      id
      url
      token
    }
  }
`;
