import { lazy } from "react";

import { Page } from "@pages/Pages";

export const ApplicationPage: Page = {
  component: lazy(() => import("./ApplicationPage")),
  path: "/:serviceOrderId/applications/:applicationId",
  createPath: (
    customerId: string,
    serviceOrderId: string,
    applicationId: string
  ) =>
    `/customers/${customerId}/service-orders/${serviceOrderId}/applications/${applicationId}`,
  name: "applications",
};
