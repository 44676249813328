import { lazy } from "react";

import { Page } from "../Pages";

export const SeasonPage: Page = {
  component: lazy(() => import("./SeasonPage")),
  path: "/registration/seasons",
  createPath: (customerId: string) =>
    `/customers/${customerId}/registration/seasons`,
  name: "seasons",
};
