import { lazy } from "react";

import { Page } from "@pages/Pages";

export const UploadLogPage: Page = {
  component: lazy(() => import("./UploadLogPage")),
  path: "/:serviceOrderId/post-application/upload-log",
  createPath: (customerId: string, serviceOrderId: string) =>
    `/customers/${customerId}/service-orders/${serviceOrderId}/post-application/upload-log`,
  name: "upload-log",
};
