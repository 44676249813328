import { gql } from "@apollo/client";

export const LIST_CUSTOMERS = gql`
  query ListCustomers($customersInput: ListCustomersInput!) {
    customers(input: $customersInput) {
      customers {
        id
        name
        location
        externalId
      }
      paging {
        limit
        offset
        total
      }
    }
  }
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($customerId: String!) {
    customer(id: $customerId) {
      id
      name
      location
      externalId
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
