import { defineMessages } from "react-intl";

export const RestrictionTypeMessages = defineMessages({
  Neighborhood: {
    defaultMessage: "Vizinhança",
    id: "ULY4Jb",
    description: "neighborhood label",
  },
  NeighboringProperty: {
    defaultMessage: "Propriedade Vizinha",
    id: "n/N/jf",
    description: "neighboring property label",
  },
  WaterSource: {
    defaultMessage: "Fonte de Água",
    id: "swdBjW",
    description: "water source label",
  },
  WaterSupply: {
    defaultMessage: "Abastecimento de Água",
    id: "/Vn2qx",
    description: "water supply label",
  },
  PermanentPreservationArea: {
    defaultMessage: "Área de Preservação Permanente",
    id: "nLUVXB",
    description: "permanent preservation area label",
  },
  Village: {
    defaultMessage: "Vila",
    id: "nOjYkD",
    description: "village label",
  },
  AnimalBreeding: {
    defaultMessage: "Criação de Animais",
    id: "4zrraW",
    description: "animal breeding label",
  },
  IsolatedHouses: {
    defaultMessage: "Casas Isoladas",
    id: "dpAsOt",
    description: "isolated houses label",
  },
  City: {
    defaultMessage: "Cidade",
    id: "w9pHt7",
    description: "city label",
  },
  Unknown: {
    defaultMessage: "Desconhecido",
    id: "IeT6wZ",
    description: "unknown label",
  },
});
