import { gql } from "@apollo/client";

export const LIST_SUMMARY_REPORTS = gql`
  query ListSummaryReports($input: FindSummaryReportInput!) {
    findSummaryReport(input: $input) {
      id
      farmId
      seasonId
      finishedIn
      startedIn
      url
      status
      expiresIn
      applicationDateType
      createdAt
      updatedAt
    }
  }
`;

export const GET_SUMMARY_REPORT = gql`
  query GetSummaryReport($input: SummaryReportInput!) {
    summaryReport(input: $input) {
      id
      farmId
      seasonId
      startedIn
      finishedIn
      expiresIn
      status
      url
      createdAt
      updatedAt
    }
  }
`;

export const SUMMARY_REPORT_POLLING = gql`
  query SummaryReportPolling($input: SummaryReportInput!) {
    summaryReport(input: $input) {
      id
      status
    }
  }
`;
