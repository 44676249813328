import { FormattedMessage } from "react-intl";

import { Box, Container, Typography } from "@mui/material";

import { Button } from "@components/Button";

import { CustomersPage } from "@pages/Customers";

import { theme } from "@theme/index";

export const ErrorPage = () => {
  const handleClick = () => {
    window.location.href = CustomersPage.createPath();
  };

  return (
    <Container sx={{ width: "100%", height: "98vh" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <img
            src={"/images/pf_full.svg"}
            alt={"logo-full"}
            style={{
              width: theme.spacing(18.406),
              height: theme.spacing(6.494),
            }}
          />
        </Box>
        <Box
          sx={{
            backgroundColor: theme.palette.error.light,
            width: theme.spacing(5.313),
            borderRadius: theme.spacing(1.25),
            textAlign: "center",
            mt: theme.spacing(4),
            mb: theme.spacing(1),
          }}
        >
          <Typography sx={{ color: theme.palette.error.dark }}>
            <FormattedMessage
              defaultMessage="Erro 500"
              id="ZrA6Fs"
              description="error 500"
            />
          </Typography>
        </Box>
        <Typography variant={"h3"} sx={{ fontWeight: 700 }}>
          <FormattedMessage
            defaultMessage="Internal Server Error"
            id="DokR4Y"
            description="internal server error label"
          />
        </Typography>
        <Typography
          variant={"body1"}
          sx={{ width: theme.spacing(29.75), textAlign: "center" }}
        >
          <FormattedMessage
            defaultMessage="Algo deu errado, ocorreu um erro da nossa parte e nossa equipe técnica já foi alertada para resolver"
            id="GIWv/d"
            description="internal server error message"
          />
        </Typography>
        <Button
          variant="contained"
          onClick={() => handleClick()}
          sx={{ mt: theme.spacing(1) }}
        >
          <FormattedMessage
            defaultMessage="Voltar para tela de clientes"
            id="5LaAvM"
            description="go back client page"
          />
        </Button>
      </Box>
    </Container>
  );
};
