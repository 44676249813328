import { useState } from "react";
import { useIntl } from "react-intl";

import { useLazyQuery } from "@apollo/client";

import { LIST_USER_PERMISSIONS } from "@graphql/queries";
import { FindUserPermissionById } from "@graphql/types/FindUserPermissionById";

import { Toast } from "@core/Toast";

import { graphQLErrorMessage } from "@utils/errorMessages";

import { ListUserPermission } from "@type/user";

export const useUserPermission = () => {
  const intl = useIntl();

  const [userPermission, setUserPermission] = useState<
    ListUserPermission[] | null
  >(null);

  const [getUserPermission, { loading: isLoadingUserPermission }] =
    useLazyQuery<FindUserPermissionById>(LIST_USER_PERMISSIONS, {
      onCompleted: ({ findUserPermissionById }) => {
        setUserPermission(findUserPermissionById);
      },
      onError: (error) => {
        const messages = graphQLErrorMessage(error);

        if (messages.length) {
          messages.forEach((message) => Toast.error(message));
        } else {
          Toast.error(
            intl.formatMessage({
              defaultMessage: "Não foi possível recuperar os dados do usuário",
              id: "dzRMoG",
              description: "error getting user data",
            })
          );
        }
      },
      fetchPolicy: "no-cache",
    });

  const onGetUserPermission = async () => {
    const { data } = await getUserPermission();
    return data?.findUserPermissionById;
  };

  return {
    onGetUserPermission,
    userPermission,
    isLoadingUserPermission,
  };
};
