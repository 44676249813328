import { lazy } from "react";

import { Page } from "@pages/Pages";

export const PropertiesPage: Page = {
  component: lazy(() => import("./PropertiesPage")),
  path: "/properties",
  createPath: (customerId: string) => `/customers/${customerId}/properties`,
  name: "properties",
};
