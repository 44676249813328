import { createContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Toast } from "@core/Toast";

import { useUserPermission } from "@hooks/customerUsers";

import {
  changePassword,
  forgotPassword,
  getUserInfo,
  sendCode,
  signInWithEmail,
  signOut,
} from "@service/cognito";

import { CustomersPage } from "@pages/Customers";
import { NewPasswordPage } from "@pages/NewPassword";
import { SignInPage } from "@pages/SignIn";

import { AuthenticateUser } from "@type/authenticateUser";
import { Credentials } from "@type/credentials";
import { ListUserPermission } from "@type/user";

interface CurrentUser {
  email: string;
  name: string;
  role: string[];
  identityId: string;
  userPermission?: ListUserPermission[];
}

export interface AuthContextType {
  isLoadingSignIn: boolean;
  temporaryCredentials: { email: string; password: string };
  currentUser: CurrentUser | null;
  isCurrentUserPilot: boolean;
  onSaveTemporaryCredentials: (credentials: {
    email: string;
    password: string;
  }) => void;
  onSignInWithEmail: (email: string, password: string) => void;
  onSendCode: (email: string) => void;
  onForgotPassword: (email: string, code: string, password: string) => void;
  onChangePassword: (newPassword: string) => void;
  onSignOut: () => void;
  onGetUser: () => void;
  onCompleteNewPassword: (newPassword: string) => void;
}

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

function AuthProvider({ children }: Props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const { onGetUserPermission } = useUserPermission();

  const [isLoadingSignIn, setIsLoadingSignIn] = useState(false);

  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [temporaryCredentials, setTemporaryCredentials] = useState<Credentials>(
    {
      email: "",
      password: "",
    }
  );
  const [isCurrentUserPilot, setIsCurrentUserPilot] = useState(false);

  const onSaveTemporaryCredentials = (credentials: Credentials) => {
    setTemporaryCredentials(credentials);
  };

  const onSignInWithEmail = async (email: string, password: string) => {
    setIsLoadingSignIn(true);
    try {
      const result: AuthenticateUser | undefined = await signInWithEmail(
        email,
        password
      );

      switch (result?.code) {
        case "onSuccess":
          setCurrentUser({
            email: result.result?.getIdToken().payload.email,
            name: result.result?.getIdToken().payload.name,
            role: result.result?.getIdToken().payload["cognito:groups"],
            identityId: result.result?.getIdToken().payload["cognito:username"],
            userPermission: await onGetUserPermission(),
          });
          navigate(CustomersPage.createPath());
          break;
        case "newPasswordRequired":
          navigate(NewPasswordPage.createPath());
          break;
        default:
          Toast.error(
            intl.formatMessage({
              defaultMessage:
                "Não foi possível realizar o login, tente novamente mais tarde",
              id: "RVdNhx",
              description: "login error label",
            })
          );
          break;
      }
    } catch (error) {
      if (error instanceof Error) {
        const { message } = error;

        switch (message) {
          case "Incorrect username or password.":
            Toast.error(
              intl.formatMessage({
                defaultMessage: "Usuário ou senha incorreto.",
                id: "yoRFR6",
                description: "incorrect username or password error label",
              })
            );
            break;
          case "Temporary password has expired and must be reset by an administrator.":
            Toast.error(
              intl.formatMessage({
                defaultMessage:
                  "A senha temporária expirou e deve ser restaurada por um administrador",
                id: "cHC7yf",
                description: "temporary password has expired error label",
              })
            );
            break;
          case "Password attempts exceeded":
            Toast.error(
              intl.formatMessage({
                defaultMessage: "Número de tentativas de senha excedida",
                id: "rOCHfT",
                description: "password attempts exceeded error label",
              })
            );
            break;
          default:
            Toast.error(
              intl.formatMessage({
                defaultMessage:
                  "Não foi possível realizar o login, tente novamente mais tarde",
                id: "RVdNhx",
                description: "login error label",
              })
            );
            break;
        }
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Não foi possível realizar o login, tente novamente mais tarde",
            id: "RVdNhx",
            description: "login error label",
          })
        );
      }

      throw error;
    } finally {
      setIsLoadingSignIn(false);
    }
  };

  const onSignOut = () => {
    signOut();
  };

  const onSendCode = async (email: string) => {
    try {
      await sendCode(email);
    } catch (error) {
      console.error("[onSendCode error]: ", error);
      Toast.error(
        intl.formatMessage({
          defaultMessage:
            "Não foi possível enviar o código de recuperação de senha, tentar novamente mais tarde!",
          id: "PJZ9dy",
          description: "error sending code",
        })
      );
      throw error;
    }
  };

  const onForgotPassword = async (
    email: string,
    code: string,
    password: string
  ) => {
    try {
      await forgotPassword(email, code, password);
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Senha alterada com sucesso!",
          id: "Duqov/",
          description: "password changed successfully",
        })
      );

      navigate(SignInPage.createPath());
    } catch (error) {
      console.error("[onForgotPassword error]: ", error);
      Toast.error(
        intl.formatMessage({
          defaultMessage:
            "O código fornecido está inválido, tente novamente mais tarde.",
          id: "ka+/V/",
          description: "error label on reset password with wrong code",
        })
      );
      throw error;
    }
  };

  const onChangePassword = async (newPassword: string) => {
    try {
      await changePassword(temporaryCredentials.password, newPassword);
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Senha alterada com sucesso!",
          id: "Duqov/",
          description: "password changed successfully",
        })
      );

      navigate(SignInPage.createPath());
    } catch (error) {
      console.error("[changePassword error]: ", error);
      Toast.error(
        intl.formatMessage({
          defaultMessage:
            "Não foi possível cadastrar a nova senha, tentar novamente mais tarde!",
          id: "gwgaSz",
          description: "error when changing password",
        })
      );
      throw error;
    }
  };

  const onCompleteNewPassword = async (newPassword: string) => {
    try {
      await signInWithEmail(
        temporaryCredentials.email,
        temporaryCredentials.password,
        newPassword
      );
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Senha alterada com sucesso!",
          id: "Duqov/",
          description: "password changed successfully",
        })
      );

      navigate(SignInPage.createPath());
    } catch (error) {
      console.error("[completeNewPassword error]: ", error);
      Toast.error(
        intl.formatMessage({
          defaultMessage:
            "Não foi possível cadastrar a nova senha, tentar novamente mais tarde!",
          id: "gwgaSz",
          description: "error when changing password",
        })
      );
      throw error;
    }
  };

  const onGetUser = async () => {
    const userInfo: any = await getUserInfo();
    const userPermission = await onGetUserPermission();

    if (userInfo) {
      setCurrentUser({
        email: userInfo.email,
        name: userInfo.name,
        role: userInfo["cognito:groups"],
        identityId: userInfo["cognito:username"],
        userPermission,
      });
    } else {
      console.error("[userInfo]: error getting user info");
    }
  };

  const setupRollbar = (user: { email: string; name: string }) => {
    if (typeof window.Rollbar !== "undefined") {
      const payload = {
        person: {
          id: user.email,
          username: user.name,
          email: user.email,
        },
      };
      window.Rollbar.configure({ payload });
    } else {
      console.warn("Rollbar is not available");
    }
  };

  useEffect(() => {
    if (currentUser) {
      setupRollbar(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      setIsCurrentUserPilot(
        !!currentUser.role.find((user) => user.includes("customer_pilot"))
      );
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider
      value={{
        isLoadingSignIn,
        temporaryCredentials,
        currentUser,
        isCurrentUserPilot,
        onSaveTemporaryCredentials,
        onSignInWithEmail,
        onSendCode,
        onForgotPassword,
        onChangePassword,
        onSignOut,
        onGetUser,
        onCompleteNewPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider };
