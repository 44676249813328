import { lazy } from "react";

import { Page } from "../Pages";

export const SignInPage: Page = {
  component: lazy(() => import("./SignInPage")),
  path: "/",
  createPath: () => "/",
  name: "sign-in",
};
