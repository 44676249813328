import { createContext, ReactNode, useMemo, useState } from "react";
import { IntlProvider } from "react-intl";

interface LanguageContextType {
  languageSelector: (language: string) => void;
}

interface LanguageProviderProps {
  children: ReactNode;
}

type SupportedLanguages = "en-US" | "es-ES" | "pt-BR";

type AvailableLocale = {
  localeCode: SupportedLanguages;
  prefix: string;
  label: string;
};

const AvailableLocales: NormalizedObjects<AvailableLocale> = {
  allIds: ["en-US", "es-ES", "pt-BR"],
  byId: {
    ["en-US"]: {
      localeCode: "en-US",
      prefix: "en",
      label: "English",
    },
    ["es-ES"]: {
      localeCode: "es-ES",
      prefix: "es",
      label: "Espagnol",
    },
    ["pt-BR"]: {
      localeCode: "pt-BR",
      prefix: "pt",
      label: "Português do Brasil",
    },
  },
};

const DEFAULT_LOCALE = "pt-BR";

export const LanguageContext = createContext<LanguageContextType>(
  {} as LanguageContextType
);

export const LanguageProvider = ({ children }: LanguageProviderProps) => {
  const [language, setLanguage] = useState("");
  const locale = useMemo(() => {
    const selectedLanguage = localStorage.getItem("language");
    if (!selectedLanguage) {
      localStorage.setItem("language", navigator.language);
    } else {
      return AvailableLocales.allIds?.includes(selectedLanguage)
        ? selectedLanguage
        : navigator.language;
    }

    return DEFAULT_LOCALE;
  }, [language, navigator.language]);

  const messages = useMemo(() => {
    switch (locale) {
      case "en-US":
        return require("../../translate/compiled-lang/en-US.json");
      case "es-ES":
        return require("../../translate/compiled-lang/es-ES.json");
      case "pt-BR":
      default:
        return require("../../translate/compiled-lang/pt-BR.json");
    }
  }, [locale]);

  const languageSelector = (language: string) => {
    localStorage.setItem("language", language);
    setLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ languageSelector }}>
      <IntlProvider
        locale={locale}
        key={locale}
        defaultLocale={DEFAULT_LOCALE}
        messages={messages}
      >
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  );
};
