import { createContext, ReactNode, useEffect, useState } from "react";

type UnitMeasurementType = "metric" | "imperial";

interface UnitMeasurementContextType {
  unitMeasurement: UnitMeasurementType;
  onSwitchMeasurementUnit: (unitMeasurement: UnitMeasurementType) => void;
}

interface UnitMeasurementProviderProps {
  children: ReactNode;
}

export const UnitMeasurementContext = createContext<UnitMeasurementContextType>(
  {} as UnitMeasurementContextType
);

export const UnitMeasurementProvider = ({
  children,
}: UnitMeasurementProviderProps) => {
  const [unitMeasurement, setUnitMeasurementSystem] =
    useState<UnitMeasurementType>(
      (localStorage.getItem("unitMeasurement") as UnitMeasurementType) ||
        "metric"
    );

  useEffect(() => {
    localStorage.setItem("unitMeasurement", unitMeasurement);
  }, [unitMeasurement]);

  const onSwitchMeasurementUnit = (unitMeasurement: UnitMeasurementType) => {
    setUnitMeasurementSystem(unitMeasurement);
  };

  return (
    <UnitMeasurementContext.Provider
      value={{ unitMeasurement, onSwitchMeasurementUnit }}
    >
      {children}
    </UnitMeasurementContext.Provider>
  );
};
