import { gql } from "@apollo/client";

export const LIST_RESTRICTED_LOCATIONS = gql`
  query ListRestrictedLocations(
    $restrictedLocationsInput: ListRestrictedLocationsInput!
  ) {
    restrictedLocations(input: $restrictedLocationsInput) {
      restrictedLocations {
        id
        customerId
        type
        externalId
        warningRadius
        geometry {
          type
          coordinates
        }
        createdAt
        updatedAt
        deletedAt
      }
      paging {
        offset
        limit
        total
      }
    }
  }
`;

export const LIST_RESTRICTED_LOCATIONS_BY_FIELDS = gql`
  query ListRestrictedLocationsByFields(
    $input: ListRestrictedLocationsByFieldsInput!
  ) {
    restrictedLocationsByFields(input: $input) {
      id
      customerId
      type
      externalId
      warningRadius
      geometry {
        type
        coordinates
      }
    }
  }
`;

export const LIST_RESTRICTED_LOCATION_TYPES = gql`
  query ListRestrictedLocationTypes {
    restrictedLocationTypes {
      id
      name
      warningRadius
      translations {
        br
        es
      }
    }
  }
`;

//upload bulk restriction field

export const SUMMARIZE_RESTRICTION_FIELD_POLL = gql`
  query SummarizeRestrictionFieldPoll($customerId: String!) {
    summarizeCustomerRestrictionFieldPoll(customerId: $customerId) {
      filename
      return
      status
    }
  }
`;

export const SUMMARIZE_RESTRICTION_FIELD_RESULT = gql`
  query SummarizeRestrictionFieldResult(
    $customerId: String!
    $filename: String!
  ) {
    summarizeCustomerRestrictionFieldResult(
      customerId: $customerId
      filename: $filename
    ) {
      extendedDataSample
      nameAlwaysPresent
      nameValueList
      parsedGeojsonUrl
      totalElements
      totalInvalidGeometriesFound
    }
  }
`;

export const PROCESS_RESTRICTION_FIELD_POLL = gql`
  query ProcessRestrictionFieldPoll($customerId: String!) {
    processCustomerRestrictionFieldPoll(customerId: $customerId) {
      filename
      return
      status
    }
  }
`;

export const PROCESS_RESTRICTION_FIELD_RESULT = gql`
  query ProcessRestrictionFieldResult(
    $customerId: String!
    $filename: String!
  ) {
    processCustomerRestrictionFieldResult(
      customerId: $customerId
      filename: $filename
    ) {
      ignoredFeaturesCount
      successfulFeaturesCount
      unsuccessfulFeaturesCount
    }
  }
`;
