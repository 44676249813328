import { defineMessages } from "react-intl";

export const ApplicationDeletedReasonMessages = defineMessages({
  DuplicatedApplication: {
    defaultMessage: "Aplicação Duplicada",
    id: "S0h7Cp",
    description: "duplicated application label",
  },
  WrongApplicationData: {
    defaultMessage: "Dados da Aplicação Incorretos",
    id: "pp93Ai",
    description: "wrong application data role label",
  },
  WrongServiceOrderApplication: {
    defaultMessage: "Dados da Ordem de Serviço Incorretos",
    id: "LWkAL2",
    description: "wrong service order data role label",
  },
});
