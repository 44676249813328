import { gql } from "@apollo/client";

export const LIST_USERS = gql`
  query ListUsers(
    $findCustomerUserInput: FindCustomerUserInput!
    $paginateCustomerUserInput: PaginateInput
    $sortCustomerUserInput: SortCustomerUserInput
  ) {
    findCustomerUser(
      findCustomerUserInput: $findCustomerUserInput
      paginateCustomerUserInput: $paginateCustomerUserInput
      sortCustomerUserInput: $sortCustomerUserInput
    ) {
      customerUsers {
        createdAt
        id
        role
        user {
          id
          email
          name
        }
      }
      total
    }
  }
`;

export const GET_USER = gql`
  query GetUser($customerId: ID!, $customerUserId: ID!) {
    findCustomerUserById(
      customerId: $customerId
      customerUserId: $customerUserId
    ) {
      id
      role
      user {
        id
        email
        name
      }
    }
  }
`;

export const LIST_USER_PERMISSIONS = gql`
  query FindUserPermissionById {
    findUserPermissionById {
      customerId
      role
      permissions
    }
  }
`;
