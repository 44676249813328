import { defineMessages } from "react-intl";

export const MeasurementUnitMessages = defineMessages({
  Acre: {
    defaultMessage: "Acre",
    id: "VUHTr9",
    description: "acre label",
  },
  Celsius: {
    defaultMessage: "Celsius",
    id: "otNqTS",
    description: "celsius label",
  },
  Degrees: {
    defaultMessage: "Graus",
    id: "QtbiWC",
    description: "degrees label",
  },
  Fahrenheit: {
    defaultMessage: "Fahrenheit",
    id: "R0TiX5",
    description: "fahrenheit label",
  },
  Hectare: {
    defaultMessage: "Hectare",
    id: "x+SJsM",
    description: "hectare label",
  },
  Kilogram: {
    defaultMessage: "Quilograma",
    id: "yDA1Q9",
    description: "kilogram label",
  },
  Kilometre: {
    defaultMessage: "Quilômetro",
    id: "0EiTq0",
    description: "kilometre label",
  },
  Litre: {
    defaultMessage: "Litro",
    id: "gg1lpr",
    description: "litre label",
  },
  Metre: {
    defaultMessage: "Metro",
    id: "LMgt6l",
    description: "metre label",
  },
  Millimeter: {
    defaultMessage: "Milímetro",
    id: "qBLmUi",
    description: "millimeter label",
  },
  Percentage: {
    defaultMessage: "Percentagem",
    id: "CKwbVk",
    description: "percentage label",
  },
});
