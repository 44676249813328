import { lazy } from "react";

import { Page } from "../Pages";

export const ForgotPasswordPage: Page = {
  component: lazy(() => import("./ForgotPasswordPage")),
  path: "/forgot-password",
  createPath: () => "/forgot-password",
  name: "forgot-password",
};
