import { LngLat } from "mapbox-gl";

export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN || "";
export const MAPBOX_MAP_STYLE = "mapbox://styles/mapbox/satellite-v9";
export const MAPBOX_MAP_CENTER_COORDINATE = new LngLat(
  -52.11989884492046,
  -14.5010516948269
);
export const MAPBOX_MINIMUM_ZOOM = 2.75;
export const MAPBOX_ENABLE_DRAG_ROTATE = false;
export const RULER_POSITION = "top-left";
