import { useContext, useEffect } from "react";

import { RootRoutes } from "@routes/RootRoutes";

import { PosthogContext } from "@contexts/Posthog";

const App: React.FC = (): JSX.Element => {
  const { initializePosthog } = useContext(PosthogContext);

  useEffect(() => {
    initializePosthog();
  }, []);

  return (
    <>
      <RootRoutes />
    </>
  );
};

export default App;
