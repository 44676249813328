import { useContext } from "react";
import { FormattedMessage } from "react-intl";

import { Box, Switch, Typography } from "@mui/material";

import { UnitMeasurementContext } from "@contexts/UnitMeasurement";

import { theme } from "@theme/index";

export const UnitMeasurementSwitch = () => {
  const { unitMeasurement, onSwitchMeasurementUnit } = useContext(
    UnitMeasurementContext
  );

  const handleSwitchChange = () => {
    const newUnitMeasurement =
      unitMeasurement === "metric" ? "imperial" : "metric";
    onSwitchMeasurementUnit(newUnitMeasurement);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      }}
    >
      <Typography variant={"body2"}>
        <FormattedMessage
          defaultMessage="Sistemas de Unidade"
          id="khdMts"
          description="unit of measurement"
        />
      </Typography>
      <Box>
        <FormattedMessage
          defaultMessage="Métrico"
          id="GVuT24"
          description="metric"
        />
        <Switch
          checked={unitMeasurement === "imperial"}
          onChange={handleSwitchChange}
        />
        <FormattedMessage
          defaultMessage="Imperial"
          id="zRltO2"
          description="imperial"
        />
      </Box>
    </Box>
  );
};
