import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { theme } from "@theme/index";

export class Toast {
  private static action = (snackbarId: SnackbarKey) => (
    <>
      <IconButton onClick={() => closeSnackbar(snackbarId)}>
        <Close sx={{ color: theme.palette.background.paper }} />
      </IconButton>
    </>
  );

  static error(msg: string) {
    enqueueSnackbar(msg, {
      variant: "error",
      preventDuplicate: true,
      style: {
        backgroundColor: theme.palette.error.main,
      },
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      action: this.action,
    });
  }

  static success(msg: string) {
    enqueueSnackbar(msg, {
      variant: "success",
      preventDuplicate: true,
      style: {
        backgroundColor: theme.palette.primary.main,
      },
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      action: this.action,
    });
  }

  static info(msg: string) {
    enqueueSnackbar(msg, {
      variant: "info",
      preventDuplicate: true,
      style: {
        backgroundColor: theme.palette.info.main,
      },
      anchorOrigin: { horizontal: "right", vertical: "top" },
      action: this.action,
    });
  }

  static warning(msg: string) {
    enqueueSnackbar(msg, {
      variant: "warning",
      preventDuplicate: true,
      style: {
        backgroundColor: theme.palette.warning.main,
      },
      anchorOrigin: {
        horizontal: "right",
        vertical: "top",
      },
      action: this.action,
    });
  }
}
