import { Box, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

export function PageLoader() {
  return (
    <LoaderWrapper>
      <img src={"/images/pf_full.svg"} alt={"logo-full"} />
      <CircularProgress color="primary" size={48} />
    </LoaderWrapper>
  );
}

const LoaderWrapper = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.zIndex.tooltip};
  width: 100vw;
  height: 100dvh;
  background-color: ${(props) => props.theme.palette.background.paper};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing(2)};

  img {
    width: ${(props) => props.theme.spacing(16)};
    height: auto;
  }
`;
