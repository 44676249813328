import { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import { AuthContext } from "@contexts/Authentication";

import { ApplicationPage } from "@pages/Application";
import { CreateApplicationPage } from "@pages/CreateApplication";
import { Error403Page, Error404Page, Error500Page } from "@pages/ErrorPage";
import { Page } from "@pages/Pages";
import { PreFlightPage } from "@pages/PreFlight";
import { ServiceOrderPage } from "@pages/ServiceOrder";
import { ServiceOrdersPage } from "@pages/ServiceOrders";
import { UploadLogPage } from "@pages/UploadLog";

const pages: Page[] = [
  ApplicationPage,
  ServiceOrderPage,
  PreFlightPage,
  UploadLogPage,
  CreateApplicationPage,
  ServiceOrdersPage,
  Error403Page,
  Error404Page,
  Error500Page,
];

const pilotPages: Page[] = [
  ServiceOrderPage,
  UploadLogPage,
  ServiceOrdersPage,
  Error403Page,
  Error404Page,
  Error500Page,
];

export const ServiceOrdersRoutes = () => {
  const { isCurrentUserPilot } = useContext(AuthContext);

  return (
    <Routes>
      {isCurrentUserPilot ? (
        <>
          {pilotPages.map((page) => (
            <Route
              key={page.path}
              path={page.path}
              element={<page.component />}
            />
          ))}
        </>
      ) : (
        <>
          {pages.map((page) => (
            <Route
              key={page.path}
              path={page.path}
              element={<page.component />}
            />
          ))}
        </>
      )}
    </Routes>
  );
};
