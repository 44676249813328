import React, { createContext, ReactNode, useContext, useEffect } from "react";
import { posthog } from "posthog-js";

import { identifyUser, initializePosthog } from "@service/posthog";

import { AuthContext } from "../Authentication";
import { CustomerContext } from "../Customer";

/**/
interface PosthogContextType {
  initializePosthog: () => void;
}

interface PosthogProviderProps {
  children: ReactNode;
}

const PosthogContext = createContext<PosthogContextType>(
  {} as PosthogContextType
);

const PosthogProvider: React.FC<PosthogProviderProps> = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  const { customer } = useContext(CustomerContext);

  useEffect(() => {
    if (currentUser) {
      identifyUser(currentUser);
    }
  }, [currentUser]);

  useEffect(() => {
    if (customer) {
      const { id: customerId, name: customerName } = customer;
      posthog.setPersonProperties({ customerName, customerId });
    } else {
      posthog.setPersonProperties({ customerName: null, customerId: null });
    }
  }, [customer]);

  return (
    <PosthogContext.Provider value={{ initializePosthog }}>
      {children}
    </PosthogContext.Provider>
  );
};

export { PosthogContext, PosthogProvider };
