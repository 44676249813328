import { lazy } from "react";

import { Page } from "@pages/Pages";

export const PreFlightPage: Page = {
  component: lazy(() => import("./PreFlightPage")),
  path: "/pre-application",
  createPath: (customerId: string) =>
    `/customers/${customerId}/service-orders/pre-application`,
  name: "pre-application",
};
