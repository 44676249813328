import { defineMessages } from "react-intl";

export const BreadcrumbMessages = defineMessages({
  customers: {
    defaultMessage: "Clientes",
    id: "URURSq",
    description: "client label",
  },
  "service-orders": {
    defaultMessage: "Ordens de Serviço",
    id: "DYI8aB",
    description: "service orders label",
  },
  applications: {
    defaultMessage: "Aplicações",
    id: "u9n6Am",
    description: "applications label",
  },
  application: {
    defaultMessage: "Aplicação",
    id: "6yY8AU",
    description: "application label",
  },
  properties: {
    defaultMessage: "Propriedades",
    id: "Jq7xGZ",
    description: "properties label",
  },
  registration: {
    defaultMessage: "Cadastros",
    id: "hvRwh5",
    description: "registrations label",
  },
  seasons: {
    defaultMessage: "Safras",
    id: "l84Qa7",
    description: "seasons label",
  },
  products: {
    defaultMessage: "Produtos",
    id: "7dCiN0",
    description: "products label",
  },
  "aerial-company": {
    defaultMessage: "Provedor de serviço",
    id: "qvhn4K",
    description: "aerial company label",
  },
  users: {
    defaultMessage: "Usuários",
    id: "vMf3pL",
    description: "users label",
  },
  reports: {
    defaultMessage: "Relatórios",
    id: "Ydbrin",
    description: "reports label",
  },
  "post-application": {
    defaultMessage: "Pós Aplicação",
    id: "oVDM98",
    description: "post application label",
  },
  "create-application": {
    defaultMessage: "Criar Aplicação",
    id: "FnNhRM",
    description: "create application label",
  },
  "upload-log": {
    defaultMessage: "Carregar Log",
    id: "ActVlL",
    description: "upload log label",
  },
  "pre-application": {
    defaultMessage: "Pré Aplicação",
    id: "jMcbhh",
    description: "pre application label",
  },
});
