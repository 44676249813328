import {
  AuthenticationDetails,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";

import { AuthenticateUser } from "../types/authenticateUser";

const userPoolId = process.env.REACT_APP_USER_POOL_ID;
const clientId = process.env.REACT_APP_USER_POOL_APP_CLIENT_ID;

const LAST_AUTH_USER_LOCAL_STORAGE_PATH = `CognitoIdentityServiceProvider.${clientId}.LastAuthUser`;

const poolData = {
  UserPoolId: `${userPoolId}`,
  ClientId: `${clientId}`,
};

const userPool: CognitoUserPool = new CognitoUserPool(poolData);

const getCognitoUser = (username?: string) => {
  if (username) {
    const userData = {
      Username: username,
      Pool: userPool,
    };

    return new CognitoUser(userData);
  } else {
    return userPool.getCurrentUser();
  }
};

export const getCurrentUser = () => {
  const user: CognitoUser | null = userPool.getCurrentUser();

  return user;
};

export const getUserInfo = async () => {
  const cognitoUser = getCognitoUser(
    localStorage.getItem(LAST_AUTH_USER_LOCAL_STORAGE_PATH) ?? undefined
  );

  if (!cognitoUser) {
    console.error("No cognito user was identified");
    return;
  }

  return new Promise(function (resolve, reject) {
    cognitoUser.getSession(function (error: Error | null) {
      if (error) return reject(error);

      const userSession = cognitoUser.getSignInUserSession();

      if (userSession) {
        const objectUserSession = userSession.getIdToken().payload;

        resolve(objectUserSession);
      } else {
        reject("User data not found");
      }
    });
  });
};

export async function verifyCode(username: string, code: string) {
  const cognitoUser = getCognitoUser(username);

  if (!cognitoUser) {
    console.error("No cognito user was identified");
    return;
  }

  return new Promise(function (resolve, reject) {
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(
  username: string,
  password: string,
  newPassword?: string
) {
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const cognitoUser = getCognitoUser(username);

  if (!cognitoUser) {
    console.error("No cognito user was identified");
    return;
  }

  return new Promise<AuthenticateUser>(function (resolve, reject) {
    // very important
    // https://stackoverflow.com/questions/58904776/aws-cognito-amplify-auth-signin-no-matter-what-returns-notauthorizedexception
    cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (res: CognitoUserSession) => {
        resolve({ code: "onSuccess", result: res });
      },
      newPasswordRequired: (userAttributes) => {
        if (newPassword) {
          const updateUserAttributes = {
            name: userAttributes.name,
            updated_at: userAttributes.updated_at,
            zoneinfo: userAttributes.zoneinfo,
          };

          cognitoUser.completeNewPasswordChallenge(
            newPassword,
            updateUserAttributes,
            {
              onSuccess: (res) => {
                resolve({ code: "onSuccess", result: res });
              },
              onFailure: (err) => {
                resolve({ code: "onFailure", result: err });
              },
            }
          );
        }

        resolve({ code: "newPasswordRequired" });
      },
      onFailure: (err) => {
        reject(new Error(err.message));
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export const completeNewPassword = async (
  email: string,
  newPassword: string
) => {
  const cognitoUser = getCognitoUser(email);
  const attr = await getAttributes();

  if (!cognitoUser) {
    console.error("No cognito user was identified");
    return;
  }

  return new Promise(function (resolve, reject) {
    cognitoUser.completeNewPasswordChallenge(newPassword, attr, {
      onSuccess: (res) => {
        resolve(res);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const signOut = (username?: string) => {
  const cognitoUser = getCognitoUser(username);
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const sendCode = async (email: string) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(email);

    if (!cognitoUser) {
      reject(`could not find ${email}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: (res) => {
        resolve(res);
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
};

export const forgotPassword = async (
  username: string,
  code: string,
  password: string
) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: () => {
        resolve("password updated");
      },
      onFailure: (err) => {
        reject(err);
      },
    });
  });
};

export const changePassword = (oldPassword: string, newPassword: string) => {
  const cognitoUser = getCognitoUser();

  if (!cognitoUser) {
    console.error("No cognito user was identified");
    return;
  }

  return new Promise(function (resolve, reject) {
    cognitoUser?.changePassword(
      oldPassword,
      newPassword,
      function (err: any, res: any) {
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      }
    );
  });
};

export const getSession: () => Promise<CognitoUserSession | null> = () => {
  const cognitoUser = getCognitoUser();

  if (!cognitoUser) {
    return Promise.reject("No cognito user was identified");
  }

  return new Promise(function (resolve, reject) {
    if (!cognitoUser) {
      reject("User is not authenticated");
    } else {
      cognitoUser.getSession(function (
        err: Error | null,
        session: CognitoUserSession | null
      ) {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    }
  });
};

export const getAttributes = async () => {
  const cognitoUser = getCognitoUser();

  if (!cognitoUser) {
    return Promise.reject("No cognito user was identified");
  }

  return new Promise(function (resolve, reject) {
    if (!cognitoUser) {
      reject("User is not authenticated");
    } else {
      cognitoUser.getUserAttributes(function (
        err: Error | undefined,
        attributes: CognitoUserAttribute[] | undefined
      ) {
        if (err) {
          reject(err);
        } else {
          resolve(attributes);
        }
      });
    }
  });
};

export const refreshSession = async () => {
  const cognitoUser = getCognitoUser();

  if (!cognitoUser) {
    return Promise.reject("No cognito user was identified");
  }

  return new Promise(function (resolve, reject) {
    if (cognitoUser) {
      const refreshAccessToken = new CognitoRefreshToken({
        RefreshToken:
          localStorage.getItem(
            `CognitoIdentityServiceProvider.${clientId}.${cognitoUser.getUsername()}.refreshToken`
          ) ?? "",
      });

      cognitoUser.refreshSession(refreshAccessToken, async (error, session) => {
        if (error) {
          reject(error);
        }
        if (session) {
          resolve(session.accessToken.jwtToken);
        }
      });
    } else {
      reject("User not authenticated");
    }
  });
};
