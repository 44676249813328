import { lazy } from "react";

import { Page } from "@pages/Pages";

export type ErrorProps = {
  errorType: string;
  errorTitle: string;
  errorMessage: string;
};

export const Error403Page: Page = {
  component: lazy(() => import("./Error403Page")),
  path: "/forbidden-access",
  createPath: () => "/forbidden-access",
  name: "forbidden-access",
};

export const Error404Page: Page = {
  component: lazy(() => import("./Error404Page")),
  path: "*",
  createPath: () => "*",
  name: "not-found",
};

export const Error500Page: Page = {
  component: lazy(() => import("./Error500Page")),
  path: "/error",
  createPath: () => "/error",
  name: "error",
};
