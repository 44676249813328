import { ListFiles } from "@type/file";

export const fileColor = (files: ListFiles[]) => {
  return files.map((file: ListFiles) => {
    const color = stringToColor(file.name);

    return {
      ...file,
      color: color,
    };
  });
};

export const stringToColor = (string: string) => {
  let hash = 0;
  let count;

  for (count = 0; count < string.length; count += 1) {
    hash = string.charCodeAt(count) + ((hash << 5) - hash);
  }

  let color = "#";

  for (count = 0; count < 3; count += 1) {
    const value = (hash >> (count * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
};
