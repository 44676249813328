import { lazy } from "react";

import { Page } from "@pages/Pages";

export const AerialCompanyPage: Page = {
  component: lazy(() => import("./AerialCompanyPage")),
  path: "/registration/aerial-company",
  createPath: (customerId: string) =>
    `/customers/${customerId}/registration/aerial-company`,
  name: "aerial-company",
};
