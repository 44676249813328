import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Box } from "@mui/material";

import { AuthContext } from "@contexts/Authentication";

import { useCustomers } from "@hooks/customers/useCustomers";

import { CustomersPage } from "@pages/Customers";
import { ServiceOrdersPage } from "@pages/ServiceOrders";

import { theme } from "@theme/index";

import { CustomerRoutes } from "./CustomerRoutes";

export const CustomersRoutes = () => {
  const { onGetUser, currentUser } = useContext(AuthContext);
  const {
    customers,
    onLoadCustomers,
    loading: isLoadingCustomers,
  } = useCustomers();
  const [hasInitialized, setHasInitialized] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const isCustomersPageURL = location.pathname === CustomersPage.path;

  useEffect(() => {
    onGetUser();
    if (isCustomersPageURL) {
      onLoadCustomers({ paging: { limit: 10, offset: 0 } });
    }
    setHasInitialized(true);
  }, [isCustomersPageURL]);

  const isLoading = !hasInitialized || isLoadingCustomers;

  useEffect(() => {
    if (isCustomersPageURL && !isLoading && customers.length === 1) {
      if (currentUser && currentUser.userPermission) {
        const isCustomerUser = currentUser.userPermission.every(
          (permission) => permission.customerId !== null,
        );

        if (isCustomerUser) navigate(ServiceOrdersPage.createPath(customers[0]?.id || ""));
      }

    }
  }, [isCustomersPageURL, customers, isLoading, currentUser]);

  if (isLoading) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.grey[50],
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Routes>
        <Route path={"/"} element={<CustomersPage.component />} />
        <Route path={"/:customerId/*"} element={<CustomerRoutes />} />
      </Routes>
    </Box>
  );
};
