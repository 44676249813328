import { lazy } from "react";

import { Page } from "../Pages";

export const CustomerUsersPage: Page = {
  component: lazy(() => import("./CustomerUsersPage")),
  path: "/registration/users",
  createPath: (customerId: string) =>
    `/customers/${customerId}/registration/users`,
  name: "users",
};
