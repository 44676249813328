import { gql } from "@apollo/client";

export const LIST_SEASON = gql`
  query ListSeasons(
    $findSeasonInput: FindSeasonInput!
    $paginateSeasonInput: PaginateInput
    $sortSeasonInput: SortSeasonInput
  ) {
    findCustomerSeason(
      findSeasonInput: $findSeasonInput
      paginateSeasonInput: $paginateSeasonInput
      sortSeasonInput: $sortSeasonInput
    ) {
      seasons {
        id
        crop
        startedIn
        createdAt
        finishedIn
        updatedAt
        deletedAt
      }
      total
    }
  }
`;

export const GET_SEASON = gql`
  query GetSeason($customerId: ID!, $seasonId: ID!) {
    findCustomerSeasonById(customerId: $customerId, seasonId: $seasonId) {
      id
      crop
      startedIn
      finishedIn
      createdAt
      updatedAt
      deletedAt
    }
  }
`;
