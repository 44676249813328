import { useContext, useState } from "react";
import { useIntl } from "react-intl";

import { useLazyQuery, useMutation } from "@apollo/client";

import { CREATE_USER, DELETE_USER } from "@graphql/mutations/UserMutation";
import { GET_USER } from "@graphql/queries/UserQuery";
import { CreateUser, CreateUserVariables } from "@graphql/types/CreateUser";
import { DeleteUser, DeleteUserVariables } from "@graphql/types/DeleteUser";
import {
  GetUser as GetUserInput,
  GetUserVariables,
} from "@graphql/types/GetUser";
import { CreateCustomerUserInput } from "@graphql/types/graphql-global-types";

import { Toast } from "@core/Toast";

import { CustomerContext } from "@contexts/Customer";

import { graphQLErrorMessage } from "@utils/errorMessages";

import { GetUser } from "@type/user";

export const useCustomerUser = () => {
  const intl = useIntl();
  const { customer } = useContext(CustomerContext);

  const [customerUser, setCustomerUser] = useState<GetUser | null>(null);

  const customerId = customer?.id || "";

  const [createCustomerUser, { loading: isLoadingCreateCustomerUser }] =
    useMutation<CreateUser, CreateUserVariables>(CREATE_USER, {
      onCompleted: () => {
        Toast.success(
          intl.formatMessage({
            defaultMessage: "Usuário criado com sucesso!",
            id: "ckbkgv",
            description: "user creation successful",
          })
        );
      },
      onError: (error) => {
        const messages = graphQLErrorMessage(error);

        if (messages.length) {
          messages.forEach((message) => Toast.error(message));
        } else {
          Toast.error(
            intl.formatMessage({
              defaultMessage:
                "Ocorreu um erro na criação do usuário, tente novamente mais tarde",
              id: "0msyvN",
              description: "error creating user",
            })
          );
        }

        throw error;
      },
    });

  const [findCustomerUserById, { loading: isLoadingFindCustomerUserById }] =
    useLazyQuery<GetUserInput, GetUserVariables>(GET_USER, {
      onCompleted: ({ findCustomerUserById }) => {
        setCustomerUser(findCustomerUserById);
      },
      onError: (error) => {
        const messages = graphQLErrorMessage(error);

        if (messages.length) {
          messages.forEach((message) => Toast.error(message));
        } else {
          Toast.error(
            intl.formatMessage({
              defaultMessage:
                "Ocorreu um erro ao buscar o usuário, tente novamente mais tarde",
              id: "WhQvkD",
              description: "error getting user",
            })
          );
        }

        throw error;
      },
      fetchPolicy: "no-cache",
    });

  const [deleteCustomerUser, { loading: isLoadingDeleteCustomerUser }] =
    useMutation<DeleteUser, DeleteUserVariables>(DELETE_USER, {
      onCompleted: () => {
        Toast.success(
          intl.formatMessage({
            defaultMessage: "Usuário excluído com sucesso!",
            id: "gsF4oE",
            description: "user deleting successful",
          })
        );
      },
      onError: (error) => {
        const messages = graphQLErrorMessage(error);

        if (messages.length) {
          messages.forEach((message) => Toast.error(message));
        } else {
          Toast.error(
            intl.formatMessage({
              defaultMessage:
                "Ocorreu um erro ao excluir o usuário, tente novamente mais tarde",
              id: "PCAuMk",
              description: "error deleting user",
            })
          );
        }

        throw error;
      },
    });

  const onCreateCustomerUser = async (
    createCustomerUserInput: CreateCustomerUserInput
  ) => {
    await createCustomerUser({
      variables: {
        createCustomerUserInput,
      },
    });
  };

  const onGetCustomerUser = async (customerUserId: string) => {
    await findCustomerUserById({
      variables: {
        customerId,
        customerUserId,
      },
    });
  };

  const onDeleteCustomerUser = async (customerUserId: string) => {
    await deleteCustomerUser({
      variables: {
        customerId,
        customerUserId,
      },
    });
  };

  return {
    onCreateCustomerUser,
    isLoadingCreateCustomerUser,
    onGetCustomerUser,
    isLoadingFindCustomerUserById,
    customerUser,
    onDeleteCustomerUser,
    isLoadingDeleteCustomerUser,
  };
};
