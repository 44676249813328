import { Suspense, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";

import { getCurrentUser } from "@service/cognito";

import { PageLoader } from "@components/PageLoader";

import { CustomersPage } from "@pages/Customers";
import { Error403Page, Error404Page, Error500Page } from "@pages/ErrorPage";
import { ForgotPasswordPage } from "@pages/ForgotPassword";
import { NewPasswordPage } from "@pages/NewPassword";
import { Page } from "@pages/Pages";
import { SignInPage } from "@pages/SignIn";

import { CustomersRoutes } from "./CustomersRoutes";

const pages: Page[] = [
  SignInPage,
  ForgotPasswordPage,

  NewPasswordPage,
  Error403Page,
  Error404Page,
  Error500Page,
];

export const RootRoutes = () => {
  const navigate = useNavigate();
  const user = getCurrentUser();

  const isSignInPage = location.pathname === SignInPage.path;

  useEffect(() => {
    if (isSignInPage && user != null) {
      navigate(CustomersPage.createPath());
    }
  }, [isSignInPage, user]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        {pages.map((page) => (
          <Route
            key={page.path}
            path={page.path}
            element={<page.component />}
          />
        ))}
        <Route path="/customers/*" element={<CustomersRoutes />} />
      </Routes>
    </Suspense>
  );
};
