import { lazy } from "react";

import { Page } from "@pages/Pages";

export const ServiceOrderPage: Page = {
  component: lazy(() => import("./ServiceOrderPage")),
  path: "/:serviceOrderId",
  createPath: (customerId: string, serviceOrderId: string) =>
    `/customers/${customerId}/service-orders/${serviceOrderId}`,
  name: "service-order",
};
