import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($createCustomerUserInput: CreateCustomerUserInput!) {
    createCustomerUser(createCustomerUserInput: $createCustomerUserInput) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($customerId: ID!, $customerUserId: ID!) {
    deleteCustomerUser(customerId: $customerId, customerUserId: $customerUserId)
  }
`;
