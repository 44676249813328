import { defineMessages } from "react-intl";

export const ProductTypeMessages = defineMessages({
  Acaricide: {
    defaultMessage: "Acaricida",
    id: "j9kMbj",
    description: "acaricida label",
  },
  Adjuvant: {
    defaultMessage: "Adjuvante",
    id: "28LROD",
    description: "adjuvant label",
  },
  Adulticide: {
    defaultMessage: "Adulticida",
    id: "efF8XW",
    description: "adulticide label",
  },
  Bactericide: {
    defaultMessage: "Bactericida",
    id: "EPNAzA",
    description: "bactericide label",
  },
  Biological: {
    defaultMessage: "Biológico",
    id: "tduiFX",
    description: "biological label",
  },
  Fertilizer: {
    defaultMessage: "Fertilizante",
    id: "ezcBlm",
    description: "fertilizer label",
  },
  FoliarNutrition: {
    defaultMessage: "Nutrição Foliar",
    id: "PKpMhf",
    description: "foliar nutrition label",
  },
  Fungicide: {
    defaultMessage: "Fungicida",
    id: "N53buw",
    description: "fungicide label",
  },
  Herbicide: {
    defaultMessage: "Herbicida",
    id: "n5bWsR",
    description: "herbicide label",
  },
  Insecticide: {
    defaultMessage: "Inseticida",
    id: "dcnDRl",
    description: "insecticide label",
  },
  Larvicide: {
    defaultMessage: "Larvicida",
    id: "us77qG",
    description: "larvicide label",
  },
  Ripener: {
    defaultMessage: "Amadurecido",
    id: "s69KPJ",
    description: "ripener label",
  },
  Nematicide: {
    defaultMessage: "Nematicida",
    id: "3045Ps",
    description: "nematicide label",
  },
  Phytohormone: {
    defaultMessage: "Fitormônio",
    id: "mzPvGn",
    description: "phytohormone label",
  },
  Unknown: {
    defaultMessage: "Desconhecido",
    id: "IeT6wZ",
    description: "unknown label",
  },
  acaricide: {
    defaultMessage: "Acaricida",
    id: "j9kMbj",
    description: "acaricida label",
  },
  adulticide: {
    defaultMessage: "Adulticida",
    id: "efF8XW",
    description: "adulticide label",
  },
  bactericide: {
    defaultMessage: "Bactericida",
    id: "EPNAzA",
    description: "bactericide label",
  },
  biological: {
    defaultMessage: "Biológico",
    id: "tduiFX",
    description: "biological label",
  },
  fertilizer: {
    defaultMessage: "Fertilizante",
    id: "ezcBlm",
    description: "fertilizer label",
  },
  foliar_nutrition: {
    defaultMessage: "Nutrição Foliar",
    id: "PKpMhf",
    description: "foliar nutrition label",
  },
  fungicide: {
    defaultMessage: "Fungicida",
    id: "N53buw",
    description: "fungicide label",
  },
  herbicide: {
    defaultMessage: "Herbicida",
    id: "n5bWsR",
    description: "herbicide label",
  },
  insecticide: {
    defaultMessage: "Inseticida",
    id: "dcnDRl",
    description: "insecticide label",
  },
  larvicide: {
    defaultMessage: "Larvicida",
    id: "us77qG",
    description: "larvicide label",
  },
  ripener: {
    defaultMessage: "Amadurecido",
    id: "s69KPJ",
    description: "ripener label",
  },
  nematicide: {
    defaultMessage: "Nematicida",
    id: "3045Ps",
    description: "nematicide label",
  },
  phytohormone: {
    defaultMessage: "Fitormônio",
    id: "mzPvGn",
    description: "phytohormone label",
  },
  unknown: {
    defaultMessage: "Desconhecido",
    id: "IeT6wZ",
    description: "unknown label",
  },
});
