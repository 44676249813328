import { gql } from "@apollo/client";

export const LIST_FIELDS_BY_CUSTOMER_AND_FILE = gql`
  query ListFieldsByCustomerAndFile($input: ListFieldsByCustomerAndFileInput) {
    fieldsByCustomerAndFile(input: $input) {
      id
      customerId
      farmId
      externalId
      totalArea
      farmHierarchy
      geometry {
        type
        coordinates
      }
      areaAccuracy
    }
  }
`;

export const LIST_FIELDS = gql`
  query ListFields($fieldsInput: ListFieldsInput!) {
    fields(input: $fieldsInput) {
      fields {
        id
        customerId
        farmId
        externalId
        totalArea
        farmHierarchy
        geometry {
          type
          coordinates
        }
      }
      paging {
        offset
        limit
        total
      }
    }
  }
`;

//upload bulk field
export const SUMMARIZE_FIELD_POLL = gql`
  query SummarizeFieldPoll($customerId: String!) {
    summarizeCustomerFieldPoll(customerId: $customerId) {
      filename
      return
      status
    }
  }
`;

export const SUMMARIZE_FIELD_RESULT = gql`
  query SummarizeFieldResult($customerId: String!, $filename: String!) {
    summarizeCustomerFieldResult(customerId: $customerId, filename: $filename) {
      extendedDataSample
      nameAlwaysPresent
      nameValueList
      parsedGeojsonUrl
      totalElements
      totalInvalidGeometriesFound
    }
  }
`;

export const PROCESS_FIELD_POLL = gql`
  query ProcessFieldPoll($customerId: String!) {
    processCustomerFieldPoll(customerId: $customerId) {
      filename
      return
      status
    }
  }
`;

export const PROCESS_FIELD_RESULT = gql`
  query ProcessFieldResult($customerId: String!, $filename: String!) {
    processCustomerFieldResult(customerId: $customerId, filename: $filename) {
      ignoredFeaturesCount
      successfulFeaturesCount
      unsuccessfulFeaturesCount
    }
  }
`;
