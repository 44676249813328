import { lazy } from "react";

import { Page } from "@pages/Pages";

export const NewPasswordPage: Page = {
  component: lazy(() => import("./NewPasswordPage")),
  path: "/new-password",
  createPath: () => "/new-password",
  name: "new-password",
};
