import { defineMessages } from "react-intl";

export const AnalysisMethodMessages = defineMessages({
  Complete: {
    defaultMessage: "Completa",
    id: "BPwnWs",
    description: "complete target label",
  },
  Partial: {
    defaultMessage: "Parcial",
    id: "m6lJ7G",
    description: "partial target label",
  },
  Border: {
    defaultMessage: "Bordadura",
    id: "WZfeGk",
    description: "border target label",
  },
});
