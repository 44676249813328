import { MouseEvent, useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import { KeyboardArrowDown } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";

import { AuthContext } from "@contexts/Authentication";
import { CustomerContext } from "@contexts/Customer";

import { resetPostHog } from "@service/posthog";

import { stringToColor } from "@utils/addColor";

import { CustomersPage } from "@pages/Customers";
import { SignInPage } from "@pages/SignIn";

import { theme } from "@theme/index";

import { UnitMeasurementSwitch } from "./UnitMeasurementSwitch";

export const UserMenu = () => {
  const navigate = useNavigate();

  const { customer } = useContext(CustomerContext);

  const [anchorElInfo, setAnchorElInfo] = useState<null | HTMLElement>(null);

  const { onSignOut, currentUser } = useContext(AuthContext);
  const isPopoverOpen = Boolean(anchorElInfo);

  const handleTogglePopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorElInfo(null);
  };

  const handleChangeEnvironment = async () => {
    handleClosePopover();
    navigate(CustomersPage.createPath());
  };

  const handleLogout = () => {
    handleClosePopover();
    onSignOut();
    navigate(SignInPage.createPath());
    resetPostHog();
  };

  const handleGetUsernameInitials = (name: string) => {
    const initials = name.match(/\b\w/g) || [];

    return initials.slice(0, 2).join("");
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: stringToColor(name),
        width: theme.spacing(2),
        height: theme.spacing(2),
        fontSize: 13,
      },
      children: `${handleGetUsernameInitials(name)}`,
    };
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <IconButton
        aria-label="nav info"
        component="span"
        size="small"
        sx={{
          p: 0,
          ml: 0,
          "&>:not(style)+:not(style)": {
            margin: 0,
          },
        }}
        onClick={handleTogglePopover}
      >
        {currentUser && <Avatar {...stringAvatar(currentUser.name)} />}
        <KeyboardArrowDown />
      </IconButton>
      <Menu
        id="info-menu"
        anchorEl={anchorElInfo}
        open={isPopoverOpen}
        onClose={handleClosePopover}
        MenuListProps={{
          "aria-labelledby": "info-button",
        }}
      >
        {customer && (
          <Box>
            <MenuItem onClick={handleChangeEnvironment}>
              <FormattedMessage
                defaultMessage="Mudar de ambiente"
                id="wb4Crx"
                description="change environment"
              />
            </MenuItem>
            <Divider />
          </Box>
        )}
        <UnitMeasurementSwitch />
        <Divider />
        <MenuItem onClick={handleLogout}>
          <FormattedMessage
            defaultMessage="Sair"
            id="ZaLbDU"
            description="exist"
          />
        </MenuItem>
      </Menu>
    </Box>
  );
};
