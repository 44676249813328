import { defineMessages } from "react-intl";

export const TimezoneMessages = defineMessages({
  AmericaAraguaina: {
    defaultMessage: "América/Araguaína",
    id: "NX6Tcp",
    description: "america araguaina timezone label",
  },
  AmericaBahia: {
    defaultMessage: "América/Bahia",
    id: "wOiSe7",
    description: "america bahia timezone label",
  },
  AmericaBelem: {
    defaultMessage: "América/Belém",
    id: "g0CI+c",
    description: "america belem timezone label",
  },
  AmericaBoaVista: {
    defaultMessage: "América/Boa Vista",
    id: "7ckiCa",
    description: "america boa Vista timezone label",
  },
  AmericaCampoGrande: {
    defaultMessage: "América/Campo Grande",
    id: "+W81Px",
    description: "america campo grande timezone label",
  },
  AmericaCuiaba: {
    defaultMessage: "América/Cuiabá",
    id: "FJlhMW",
    description: "america cuiaba timezone label",
  },
  AmericaEirunepe: {
    defaultMessage: "América/Eirunepé",
    id: "sSH7e3",
    description: "america eirunepe timezone label",
  },
  AmericaFortaleza: {
    defaultMessage: "América/Fortaleza",
    id: "po2RqG",
    description: "america fortaleza timezone label",
  },
  AmericaMaceio: {
    defaultMessage: "América/Maceió",
    id: "3CmAr8",
    description: "america maceio timezone label",
  },
  AmericaManaus: {
    defaultMessage: "América/Manaus",
    id: "V6SNS3",
    description: "america manaus timezone label",
  },
  AmericaNewYork: {
    defaultMessage: "América/Nova Iorque",
    id: "XJ/Ows",
    description: "america new york timezone label",
  },
  AmericaNoronha: {
    defaultMessage: "América/Noronha",
    id: "C1oege",
    description: "america noronha timezone label",
  },
  AmericaPortoAcre: {
    defaultMessage: "América/Porto Acre",
    id: "+gNc3O",
    description: "america porto acre timezone label",
  },
  AmericaPortoVelho: {
    defaultMessage: "América/Porto Velho",
    id: "MzXz9q",
    description: "america porto velho timezone label",
  },
  AmericaRecife: {
    defaultMessage: "América/Recife",
    id: "WwDhq7",
    description: "america recife timezone label",
  },
  AmericaRioBranco: {
    defaultMessage: "América/Rio Branco",
    id: "FUEFoa",
    description: "america rio branco timezone label",
  },
  AmericaSantarem: {
    defaultMessage: "América/Santarém",
    id: "aY++CA",
    description: "america santarem timezone label",
  },
  AmericaSaoPaulo: {
    defaultMessage: "América/São Paulo",
    id: "+v/kJq",
    description: "america sao paulo timezone label",
  },
  AmericaTegucigalpa: {
    defaultMessage: "América/Tegucigalpa",
    id: "9EMMKc",
    description: "america tegucigalpa timezone label",
  },
});
