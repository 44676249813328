import { lazy } from "react";

import { Page } from "../Pages";

export const CustomersPage: Page = {
  component: lazy(() => import("./CustomersPage")),
  path: "/customers",
  createPath: () => "/customers",
  name: "customers",
};
