import { useContext, useState } from "react";
import { useIntl } from "react-intl";

import { useLazyQuery, useMutation } from "@apollo/client";

import {
  CREATE_POST_FLIGHT,
  CREATE_PRE_FLIGHT,
  CREATE_SERVICE_ORDER,
  DELETE_SERVICE_ORDER,
  UPDATE_SERVICE_ORDER,
  UPDATE_SERVICE_ORDER_STATUS,
} from "@graphql/mutations/ServiceOrderMutation";
import {
  GET_SERVICE_ORDER,
  GET_SERVICE_ORDER_FIELDS,
  GET_SERVICE_ORDER_SCHEDULE,
  GET_SERVICE_ORDER_POST_APPLICATION,
} from "@graphql/queries/ServiceOrderQuery";
import {
  CreatePostFlight,
  CreatePostFlightVariables,
} from "@graphql/types/CreatePostFlight";
import {
  CreatePreFlight,
  CreatePreFlightVariables,
} from "@graphql/types/CreatePreFlight";
import {
  CreateServiceOrder,
  CreateServiceOrderVariables,
} from "@graphql/types/CreateServiceOrder";
import {
  DeleteServiceOrder,
  DeleteServiceOrderVariables,
} from "@graphql/types/DeleteServiceOrder";
import {
  GetServiceOrder as GetServiceOrderInput,
  GetServiceOrderVariables,
} from "@graphql/types/GetServiceOrder";
import {
  GetServiceOrderFields as GetServiceOrderFieldsInput,
  GetServiceOrderFieldsVariables,
} from "@graphql/types/GetServiceOrderFields";
import {
  GetServiceOrderSchedules as GetServiceOrderSchedulesInput,
  GetServiceOrderSchedulesVariables,
} from "@graphql/types/GetServiceOrderSchedules";
import {
  GetServiceOrderPostApplication,
  GetServiceOrderPostApplication_findCustomerServiceOrderById,
  GetServiceOrderPostApplicationVariables,
} from "@graphql/types/GetServiceOrderPostApplication";
import {
  CreatePostFlightInput,
  CreatePreFlightInput,
  ServiceOrderFieldInput,
  ServiceOrderScheduleInput,
  ServiceOrderStatus,
  UpdateServiceOrderScheduleInput,
} from "@graphql/types/graphql-global-types";
import {
  UpdateServiceOrder,
  UpdateServiceOrderVariables,
} from "@graphql/types/UpdateServiceOrder";
import {
  UpdateServiceOrderStatus,
  UpdateServiceOrderStatusVariables,
} from "@graphql/types/UpdateServiceOrderStatus";

import { Toast } from "@core/Toast";

import { CustomerContext } from "@contexts/Customer";

import { graphQLErrorMessage } from "@utils/errorMessages";

import {
  GetServiceOrder,
  GetServiceOrderFields,
  GetServiceOrderSchedules,
} from "@type/service_order";

export const useServiceOrder = () => {
  const intl = useIntl();

  const { customer } = useContext(CustomerContext);
  const customerId = customer?.id || "";

  const [serviceOrder, setServiceOrder] = useState<GetServiceOrder | null>(
    null
  );
  const [serviceOrderPostApplication, setServiceOrderPostApplication] =
    useState<GetServiceOrderPostApplication_findCustomerServiceOrderById | null>(
      null
    );

  const [serviceOrderFields, setServiceOrderFields] =
    useState<GetServiceOrderFields | null>(null);
  const [serviceOrderSchedules, setServiceOrderSchedules] =
    useState<GetServiceOrderSchedules | null>(null);

  const [getServiceOrder, { loading: isLoadingServiceOrder }] = useLazyQuery<
    GetServiceOrderInput,
    GetServiceOrderVariables
  >(GET_SERVICE_ORDER, {
    onCompleted: ({ findCustomerServiceOrderById }) => {
      setServiceOrder(findCustomerServiceOrderById);
    },
    fetchPolicy: "no-cache",
  });

  const onGetServiceOrder = (serviceOrderId: string) =>
    getServiceOrder({
      variables: {
        customerId,
        serviceOrderId,
      },
    });

  const [createServiceOrder] = useMutation<
    CreateServiceOrder,
    CreateServiceOrderVariables
  >(CREATE_SERVICE_ORDER, {
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao criar a ordem de serviço. Tente novamente mais tarde!",
            id: "nJfYsH",
            description: "error creating service order",
          })
        );
      }

      throw error;
    },
  });

  const onCreateServiceOrder = async ({
    serviceProviderId,
    externalId,
    fields,
    schedules,
    seasonId,
  }: {
    serviceProviderId: string;
    externalId: string;
    status?: ServiceOrderStatus | null;
    seasonId?: string | null;
    fields?: (ServiceOrderFieldInput | null)[] | null;
    schedules?: (ServiceOrderScheduleInput | null)[] | null;
  }) => {
    return createServiceOrder({
      variables: {
        input: {
          customerId,
          serviceProviderId,
          externalId,
          fields,
          schedules,
          harvestId: seasonId,
        },
      },
    });
  };

  const [deleteServiceOrder, { loading: isLoadingDeleteSO }] = useMutation<
    DeleteServiceOrder,
    DeleteServiceOrderVariables
  >(DELETE_SERVICE_ORDER, {
    onCompleted: () => {
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Ordem de Serviço excluída com sucesso!",
          id: "eKH1DW",
          description: "service order deleting successfully",
        })
      );
    },
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao excluir a ordem de serviço, tente novamente mais tarde",
            id: "GAkxn0",
            description: "error deleting service order",
          })
        );
      }
    },
    ignoreResults: true,
  });

  const onDeleteServiceOrder = (serviceOrderId: string) =>
    deleteServiceOrder({
      variables: {
        deleteServiceOrderId: serviceOrderId,
      },
    });

  const [updateServiceOrder, { loading: isLoadingUpdateSO }] = useMutation<
    UpdateServiceOrder,
    UpdateServiceOrderVariables
  >(UPDATE_SERVICE_ORDER, {
    onCompleted: () => {
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Ordem de Serviço atualizada com sucesso!",
          id: "BicP/A",
          description: "service order updating successfully",
        })
      );
    },
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao atualizar a ordem de serviço, tente novamente mais tarde",
            id: "UFtCrJ",
            description: "error updating service order",
          })
        );
      }
    },
  });

  const onUpdateServiceOrder = (
    id: string,
    externalId: string,
    schedules: UpdateServiceOrderScheduleInput[]
  ) =>
    updateServiceOrder({
      variables: {
        input: {
          customerId,
          id,
          externalId,
          schedules,
        },
      },
    });

  const [updateServiceOrderStatus] = useMutation<
    UpdateServiceOrderStatus,
    UpdateServiceOrderStatusVariables
  >(UPDATE_SERVICE_ORDER_STATUS, {
    onError: () => {
      Toast.error(
        intl.formatMessage({
          defaultMessage:
            "Ocorreu um erro ao atualizar o status da ordem de serviço, tente novamente mais tarde",
          id: "km3wAX",
          description: "error updating service order status",
        })
      );
    },
  });

  const onUpdateServiceOrderStatus = (
    serviceOrderId: string,
    status: ServiceOrderStatus
  ) =>
    updateServiceOrderStatus({
      variables: {
        updateCustomerServiceOrderStatusInput: {
          customerId,
          serviceOrderId,
          status,
        },
      },
    });

  const [createPreFlight, { loading: isCreatingPreFlight }] = useMutation<
    CreatePreFlight,
    CreatePreFlightVariables
  >(CREATE_PRE_FLIGHT, {
    onCompleted: () => {
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Ordem de Serviço criada com sucesso!",
          id: "9G1lF5",
          description: "service order created successfully",
        })
      );
    },
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao criar a ordem de serviço. Tente novamente mais tarde!",
            id: "nJfYsH",
            description: "error creating service order",
          })
        );
      }

      throw error;
    },
  });

  const onCreatePreFlight = (createPreFlightInput: CreatePreFlightInput) =>
    createPreFlight({
      variables: {
        createPreFlightInput,
      },
    });

  const [postFlight, { loading: isCreatingPostFlight }] = useMutation<
    CreatePostFlight,
    CreatePostFlightVariables
  >(CREATE_POST_FLIGHT, {
    onCompleted: () => {
      Toast.success(
        intl.formatMessage({
          defaultMessage: "Ordem de Serviço criada com sucesso!",
          id: "9G1lF5",
          description: "service order created successfully",
        })
      );
    },
    onError: (error) => {
      const messages = graphQLErrorMessage(error);

      if (messages.length) {
        messages.forEach((message) => Toast.error(message));
      } else {
        Toast.error(
          intl.formatMessage({
            defaultMessage:
              "Ocorreu um erro ao criar a ordem de serviço. Tente novamente mais tarde!",
            id: "nJfYsH",
            description: "error creating service order",
          })
        );
      }

      throw error;
    },
  });

  const onCreatePostFlight = (createPostFlightInput: CreatePostFlightInput) =>
    postFlight({
      variables: {
        createPostFlightInput,
      },
    });

  const [getServiceOrderFields, { loading: isLoadingServiceOrderFields }] =
    useLazyQuery<GetServiceOrderFieldsInput, GetServiceOrderFieldsVariables>(
      GET_SERVICE_ORDER_FIELDS,
      {
        onCompleted: ({ findCustomerServiceOrderById }) => {
          setServiceOrderFields(findCustomerServiceOrderById);
        },
      }
    );

  const onGetServiceOrderFields = (serviceOrderId: string) =>
    getServiceOrderFields({
      variables: {
        serviceOrderId,
        customerId,
      },
    });

  const [
    getServiceOrderSchedules,
    { loading: isLoadingServiceOrderSchedules },
  ] = useLazyQuery<
    GetServiceOrderSchedulesInput,
    GetServiceOrderSchedulesVariables
  >(GET_SERVICE_ORDER_SCHEDULE, {
    onCompleted: ({ findCustomerServiceOrderById }) => {
      setServiceOrderSchedules(findCustomerServiceOrderById);
    },
  });

  const onGetServiceOrderSchedules = (serviceOrderId: string) =>
    getServiceOrderSchedules({
      variables: {
        serviceOrderId,
        customerId,
      },
    });

  const [
    getServiceOrderPostApplication,
    { loading: isLoadingServiceOrderPostApplication },
  ] = useLazyQuery<
    GetServiceOrderPostApplication,
    GetServiceOrderPostApplicationVariables
  >(GET_SERVICE_ORDER_POST_APPLICATION, {
    onCompleted: ({ findCustomerServiceOrderById }) => {
      setServiceOrderPostApplication(findCustomerServiceOrderById);
    },
    fetchPolicy: "no-cache",
  });

  const onGetServiceOrderPostApplication = (serviceOrderId: string) =>
    getServiceOrderPostApplication({
      variables: {
        serviceOrderId,
        customerId,
      },
    });

  return {
    onGetServiceOrder,
    serviceOrder,
    isLoadingServiceOrder,

    onCreateServiceOrder,

    onDeleteServiceOrder,
    isLoadingDeleteSO,

    onUpdateServiceOrder,
    isLoadingUpdateSO,

    onUpdateServiceOrderStatus,

    onCreatePreFlight,
    isCreatingPreFlight,

    onCreatePostFlight,
    isCreatingPostFlight,

    serviceOrderFields,
    isLoadingServiceOrderFields,
    onGetServiceOrderFields,

    serviceOrderSchedules,
    isLoadingServiceOrderSchedules,
    onGetServiceOrderSchedules,

    serviceOrderPostApplication,
    isLoadingServiceOrderPostApplication,
    onGetServiceOrderPostApplication,
  };
};
