import { lazy } from "react";

import { Page } from "../Pages";

export const ProductsPage: Page = {
  component: lazy(() => import("./ProductsPage")),
  path: "/registration/products",
  createPath: (customerId: string) =>
    `/customers/${customerId}/registration/products`,
  name: "products",
};
